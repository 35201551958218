import type {BasePeerConnection, DataChannelInit} from '@pexip/peer-connection';
import {
    createEventQueue,
    createMainPeerConnection,
    isTransceiverInit,
    isDataChannelInit,
    isTransceiverConfig,
} from '@pexip/peer-connection';
import type {AudioQualityStats} from '@pexip/peer-connection-stats';
import {createStatsCollector} from '@pexip/peer-connection-stats';
import {isEmpty, createAsyncQueue} from '@pexip/utils';

import {logger} from './logger';
import type {
    Call,
    CallOptions,
    Client,
    DataChannelEvent,
    IceCandidate,
    NormalizedPresentationEvent,
    PresoState,
    StatsCollectors,
    Turn,
} from './types';
import {ClientCallType} from './types';
import {
    getDirection,
    isAudioConfig,
    isMainConfig,
    isPresentatioRevoked,
    isPresoConfig,
    isPresoVideo,
    toIceCandidate,
} from './utils';
import {eventSignals} from './eventSource';
import {isNotUndefined} from './typeGuards';

export const createCall = (options: CallOptions): Call => {
    const presoState = new Proxy(
        {
            send: 'new',
            recv: 'new',
        } as PresoState,
        {
            set: (target, p: keyof PresoState, value) => {
                const didUpdate = Reflect.set(target, p, value);
                if (didUpdate) {
                    options.callSignals.onPresentationConnectionChange.emit(
                        target,
                    );
                }
                return didUpdate;
            },
        },
    );
    const pc = createPCCall(options);

    const disconnect = () => {
        pc.cleanup();
        pc.close();

        presoState.recv = 'disconnected';
        presoState.send = 'disconnected';
    };

    const setStream = (stream: MediaStream) => {
        options.pcMainSignals.onOfferRequired.emit({
            stream,
            target: pc.peer
                .getTransceiverConfigs()
                .flatMap(config => (isMainConfig(config) ? [[config]] : [])),
        });
    };

    const setBandwidth = (bandwidth: number) => {
        pc.peer.bandwidth = bandwidth;
    };

    const present = async (stream?: MediaStream) => {
        if (!stream) {
            return;
        }

        logger.debug({presoState, stream}, 'present');

        try {
            presoState.send = 'connecting';
            presoState.recv = 'disconnected';

            const res = await options.takeFloor({});
            if (res?.status === 403) {
                throw new Error('Presentation rejected');
            }
            // Lookup the main video config
            const mainVideoConfig = pc.peer.configs.find(
                config => config.kind === 'video' && config.content === 'main',
            );
            if (isTransceiverConfig(mainVideoConfig)) {
                // Lower the main video priority
                // as it is often better overall user experience is obtained by
                // lowering the priority of things that are not as important
                // rather than raising the priority of the things that are.
                // Ref. https://www.w3.org/TR/webrtc-priority/#rtc-priority-type
                mainVideoConfig.sendEncodings = [
                    {priority: 'very-low', networkPriority: 'very-low'},
                ];
            }
            await pc.peer.setLocalStream(
                stream,
                pc.peer
                    .getTransceiverConfigs()
                    .flatMap(config =>
                        isPresoVideo(config) ? [[config, 'sendonly']] : [],
                    ),
            );

            presoState.send = 'connected';
        } catch (error) {
            logger.warn({presoState, stream}, 'failed to present');
            presoState.send = 'failed';
        }
    };

    const stopPresenting = async () => {
        logger.debug({presoState}, 'stopPresenting');
        // Restore the priority of main video
        const mainVideoConfig = pc.peer.configs.find(
            config => config.kind === 'video' && config.content === 'main',
        );
        if (isTransceiverConfig(mainVideoConfig)) {
            mainVideoConfig.sendEncodings = [
                {priority: 'low', networkPriority: 'low'},
            ];
        }
        await options.releaseFloor({});
        await pc.peer.setLocalStream(
            undefined,
            pc.peer
                .getTransceiverConfigs()
                .flatMap(config =>
                    isPresoVideo(config) ? [[config, 'inactive']] : [],
                ),
        );
        presoState.send = 'disconnected';
    };

    const receivePresentation = async (event: NormalizedPresentationEvent) => {
        if (['connecting', 'connected'].includes(presoState.recv)) {
            return;
        }
        logger.debug({presoState}, 'receivePresentation');

        presoState.recv = 'connecting';

        const slidesConfigs = pc.peer
            .getTransceiverConfigs()
            .flatMap(config => (isPresoVideo(config) ? [config] : []));

        if (presoState.send === 'connected') {
            await pc.peer.setLocalStream(
                undefined,
                slidesConfigs.map(config => [config, 'inactive']),
            );
            await options.releaseFloor({});
            presoState.send = 'disconnected';
        }

        if (isPresentatioRevoked(event)) {
            presoState.recv = 'disconnected';
            return;
        }

        const [slidesConfig] = slidesConfigs;
        if (slidesConfig) {
            slidesConfig.direction = 'recvonly';
            slidesConfig.syncDirection();
        }

        presoState.recv = 'connected';
    };

    const stopReceivingPresentation = () => {
        logger.debug({presoState}, 'stopReceivingPresentation');
        if (
            presoState.recv === 'connected' ||
            presoState.recv === 'connecting'
        ) {
            presoState.recv = 'disconnected';
        }
        const [slidesConfig] = pc.peer
            .getTransceiverConfigs()
            .flatMap(config => (isPresoVideo(config) ? [config] : []));
        if (
            slidesConfig &&
            presoState.send !== 'connected' &&
            presoState.send !== 'connecting'
        ) {
            slidesConfig.direction = 'inactive';
            slidesConfig.syncDirection();
        }
    };

    const sendDataChannelEvent = (event: DataChannelEvent) => {
        if (!pc.dataChannel || pc.dataChannel.readyState !== 'open') {
            logger.warn(
                {eventType: event.type},
                'DataChannel not open. Cannot send event.',
            );
            return;
        }
        pc.dataChannel.send(JSON.stringify(event));
    };

    return {
        get presoState() {
            return presoState;
        },
        get mediaStream() {
            const config = pc.peer.getTransceiverConfigs().find(config => {
                return isMainConfig(config) && config.streams[0];
            });
            return config?.streams[0];
        },
        get bandwidth() {
            return pc.peer.bandwidth;
        },
        disconnect,
        present,
        receivePresentation,
        setBandwidth,
        setStream,
        stopPresenting,
        stopReceivingPresentation,
        sendDataChannelEvent,
    };
};

const createPCCall = ({
    ack,
    sendOffer,
    update,
    newCandidate,
    peerOptions,
    signals,
    pcMainSignals,
    mainStatsSignals,
    mediaStream,
    callSignals,
    dataChannelId,
    isDirectMedia,
    callType = ClientCallType.AudioVideo,
}: CallOptions) => {
    let called = false;
    let turn443 = false;
    let apiOneTimeCallbacks: Partial<Record<keyof Client, Array<() => void>>> =
        {};
    let videoPacketsReceived = 0;
    const incomingICECandidateQueue = createEventQueue(
        pcMainSignals.onReceiveIceCandidate.emit,
    );
    let updateController: undefined | AbortController;

    const queue = createAsyncQueue({throttleInMS: 0, delayInMS: 0, size: 100});

    const statsCollectors: StatsCollectors = {inbound: {}, outbound: {}};
    const createPC = (rtcConfig = peerOptions.rtcConfig, polite?: boolean) => {
        const audioDirection = getDirection('audio', callType);
        const videoDirection = getDirection('video', callType);
        const [audioTrack] = mediaStream?.getAudioTracks() ?? [];
        const [videoTrack] = mediaStream?.getVideoTracks() ?? [];
        const streams = mediaStream && [mediaStream];
        const peer = createMainPeerConnection(pcMainSignals, {
            ...peerOptions,
            polite,
            rtcConfig,
            mediaInits: [
                audioDirection
                    ? {
                          content: 'main',
                          direction: audioDirection,
                          kindOrTrack: audioTrack ?? 'audio',
                          streams,
                          allowAutoChangeOfDirection:
                              callType === ClientCallType.AudioVideo,
                          relativeDirection: isDirectMedia,
                      }
                    : false,
                videoDirection
                    ? {
                          content: 'main',
                          direction: videoDirection,
                          kindOrTrack: videoTrack ?? 'video',
                          streams,
                          allowAutoChangeOfDirection:
                              callType === ClientCallType.AudioVideo,
                          relativeDirection: isDirectMedia,
                      }
                    : false,
                {
                    content: 'slides',
                    direction: 'inactive',
                    kindOrTrack: 'video',
                },
                dataChannelId
                    ? ({
                          label: 'pexChannel',
                          negotiated: true,
                          id: dataChannelId,
                          eventListeners: [
                              {
                                  event: 'message',
                                  listener: event => {
                                      try {
                                          const msg = JSON.parse(
                                              event.data,
                                          ) as DataChannelEvent;

                                          switch (msg.type) {
                                              case 'message':
                                                  eventSignals.onMessage.emit({
                                                      ...msg.body,
                                                      direct: false,
                                                  });
                                                  break;

                                              case 'fecc':
                                                  eventSignals.onFecc.emit(
                                                      msg.body,
                                                  );
                                                  break;
                                          }
                                      } catch (error) {
                                          logger.error(
                                              {error, event},
                                              'Failed to parse DataChannel event data from server.',
                                          );
                                      }
                                  },
                              },
                          ],
                      } satisfies DataChannelInit)
                    : false,
            ].flatMap(init =>
                isTransceiverInit(init) || isDataChannelInit(init)
                    ? [init]
                    : [],
            ),
        });
        return peer;
    };

    let peer = createPC();

    const addIceServers = (iceServers: RTCIceServer[]) => {
        const rtcConfig = peer.getConfiguration();
        return isEmpty(iceServers)
            ? rtcConfig
            : {
                  ...rtcConfig,
                  iceServers: [...(rtcConfig.iceServers ?? []), ...iceServers],
              };
    };

    const executeOneTimeApiCallback = (func: keyof Client) => {
        const callbacks = apiOneTimeCallbacks[func];
        if (callbacks) {
            callbacks.forEach(cb => cb());
            apiOneTimeCallbacks[func] = undefined;
        }
    };

    const sendCandidate = (candidate: IceCandidate) => {
        queue.enqueue(async () => {
            await newCandidate({
                candidate,
            });
            executeOneTimeApiCallback('newCandidate');
        });
    };

    // Outgoing ICE Candidate buffer is needed since the initial offer could be
    // ignored and the Peer Connection is re-created.
    const outgoingICECandidateQueue = createEventQueue(sendCandidate);

    const addOnceApiCallback = (func: keyof Client, cb: () => void) => {
        const cbArray = apiOneTimeCallbacks[func];
        if (cbArray) {
            cbArray.push(cb);
        } else {
            apiOneTimeCallbacks[func] = [cb];
        }
    };

    const createStatsCollectors = () => {
        peer.getTransceiverConfigs().forEach(config => {
            if (isMainConfig(config) && config.transceiver) {
                statsCollectors.inbound[config.kind] = createStatsCollector({
                    input: config.transceiver.receiver,
                    signals: isAudioConfig(config)
                        ? mainStatsSignals.audioIn
                        : mainStatsSignals.videoIn,
                });
                statsCollectors.outbound[config.kind] = createStatsCollector({
                    input: config.transceiver.sender,
                    signals: isAudioConfig(config)
                        ? mainStatsSignals.audioOut
                        : mainStatsSignals.videoOut,
                });
            }
            if (isPresoVideo(config) && config.transceiver) {
                statsCollectors.inbound.preso = createStatsCollector({
                    input: config.transceiver.receiver,
                    signals: mainStatsSignals.presoVideoIn,
                });
                statsCollectors.outbound.preso = createStatsCollector({
                    input: config.transceiver.sender,
                    signals: mainStatsSignals.presoVideoOut,
                });
            }
        });
    };

    const cleanupStats = () => {
        statsCollectors.inbound.audio?.cleanup();
        statsCollectors.outbound.audio?.cleanup();
        statsCollectors.inbound.video?.cleanup();
        statsCollectors.outbound.video?.cleanup();
        statsCollectors.inbound.preso?.cleanup();
        statsCollectors.outbound.preso?.cleanup();
    };

    const restartIce = (targetPeer: BasePeerConnection) => {
        if (!['new', 'closed'].includes(targetPeer.connectionState)) {
            targetPeer.restartIce();
        }
    };

    const handleAck = (turn?: Turn) => {
        turn443 = false;
        if (!turn) {
            return;
        }

        const rtcConfig = addIceServers(turn);
        if (peer.setConfiguration) {
            peer.setConfiguration(rtcConfig);
            peer.restartIce();
        } else {
            peer.close();
            peer = createPC(rtcConfig);
            pcMainSignals.onOfferRequired.emit(
                mediaStream && {
                    stream: mediaStream,
                    target: peer
                        .getTransceiverConfigs()
                        .flatMap(config =>
                            isMainConfig(config) ? [[config]] : [],
                        ),
                },
            );
        }
    };

    const releaseIncomingCandidateBuffer = () => {
        incomingICECandidateQueue.buffering = false;
        const candidatesFlushed = incomingICECandidateQueue.flush();
        logger.debug(
            {incomingCandidates: candidatesFlushed},
            'release buffered incoming candidates',
        );
    };
    const releaseOutGoingCandidateBuffer = () => {
        outgoingICECandidateQueue.buffering = false;
        const candidatesFlushed = outgoingICECandidateQueue.flush();
        logger.debug(
            {outgoingCandidates: candidatesFlushed},
            'release buffered outgoing candidates',
        );
    };

    let detachApiSignals = [
        signals.onAnswer.add(({sdp, turn, offer_ignored}) => {
            if (isDirectMedia && (offer_ignored || !sdp) && !called) {
                // Discard the call and wait for `new_offer` for direct media
                // Assign polite peer role to the new Peer Connection instance
                logger.info(
                    {sdp, turn, offer_ignored, called},
                    'Initial Offer being ignored, restart the peer connection.',
                );
                // ICE candidates for the previous Peer Connection are obsolete
                outgoingICECandidateQueue.discard();
                peer.close();
                peer = createPC(peerOptions.rtcConfig, true);
                return;
            }
            if (offer_ignored || !sdp) {
                return;
            }
            releaseOutGoingCandidateBuffer();
            const answer = new RTCSessionDescription({
                sdp,
                type: 'answer',
            });
            turn443 = Boolean(turn);

            queue.enqueue(async () => {
                await ack({});
                handleAck(turn);
            });

            pcMainSignals.onReceiveAnswer.emit(answer);
            releaseIncomingCandidateBuffer();
        }),
        signals.onNewOffer.add(sdp => {
            if (!sdp) {
                return;
            }
            const offer = new RTCSessionDescription({
                sdp,
                type: 'offer',
            });
            // No need to buffer out going ICE candidates
            outgoingICECandidateQueue.buffering = false;
            pcMainSignals.onReceiveOffer.emit(offer);
            releaseIncomingCandidateBuffer();
        }),
        signals.onUpdateSdp.add(sdp => {
            if (!sdp) {
                return;
            }
            const offer = new RTCSessionDescription({
                sdp,
                type: 'offer',
            });
            // No need to buffer out going ICE candidates
            outgoingICECandidateQueue.buffering = false;
            pcMainSignals.onReceiveOffer.emit(offer);
            releaseIncomingCandidateBuffer();
        }),
        signals.onIceCandidate.add(candidate => {
            // Strip unnecessary properties to please Safari when end-of-candidates.
            if (candidate.candidate === '') {
                // Manually indicated end-of-candidates
                candidate = {candidate: ''} as RTCIceCandidate;
            }
            if (incomingICECandidateQueue.buffering) {
                logger.debug({candidate}, 'buffering incoming candidates');
            }
            incomingICECandidateQueue.enqueue(candidate);
        }),
    ];

    let detachPCSignals = [
        pcMainSignals.onOffer.add(({sdp}) => {
            if (!sdp) {
                return;
            }
            incomingICECandidateQueue.buffering = true;
            queue.enqueue(async () => {
                if (called) {
                    updateController = new AbortController();
                    await update({sdp, abortSignal: updateController.signal});
                    updateController = undefined;
                } else {
                    await sendOffer({sdp});
                    called = true;
                }
            });
        }),

        pcMainSignals.onOfferIgnored.add(() => {
            void ack({offerIgnored: true});
        }),

        pcMainSignals.onAnswer.add(({sdp}) => {
            updateController?.abort(); // Abort current update call
            queue.enqueue(async () => {
                await ack({sdp});
                handleAck();
            });
        }),

        pcMainSignals.onRemoteStreams.add(transceiverConfig => {
            logger.debug(
                {transceiverConfig, isDirectMedia},
                'Received remote streams',
            );
            const signal = isPresoConfig(transceiverConfig)
                ? callSignals.onRemotePresentationStream
                : callSignals.onRemoteStream;
            if (isDirectMedia) {
                transceiverConfig.remoteStreams?.forEach(stream => {
                    logger.debug(
                        {
                            stream,
                            tracks: stream.getTracks(),
                        },
                        'Emits remote stream',
                    );
                    signal.emit(stream);
                });
                return;
            }
            const tracks = (
                isPresoConfig(transceiverConfig)
                    ? [transceiverConfig.transceiver?.receiver.track]
                    : peer
                          .getTransceiverConfigs()
                          .filter(isMainConfig)
                          .map(config => config.transceiver?.receiver.track)
            ).filter(isNotUndefined);
            logger.debug(
                {
                    transceiverConfig,
                    transceiverConfigs: peer.getTransceiverConfigs(),
                    tracks,
                },
                'Emits remote streams based on transceivers',
            );
            signal.emit(new MediaStream(tracks));
        }),

        pcMainSignals.onIceCandidate.add(event => {
            if (turn443) {
                // no need to send dead candidates as we will be doing a restart on TURN443 in answer
                return;
            }
            const candidate = toIceCandidate(event);
            outgoingICECandidateQueue.enqueue(candidate);
        }),

        pcMainSignals.onConnectionStateChange.add(connectionState => {
            switch (connectionState) {
                case 'connected': {
                    callSignals.onCallConnected.emit();
                    break;
                }
                case 'closed': {
                    signals.onError.emit({
                        error: 'WebRTC connection closed',
                        errorCode: '#pex117',
                    });
                    break;
                }
                case 'failed': {
                    signals.onError.emit({
                        error: 'WebRTC connection failed',
                        errorCode: '#pex128',
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }),

        pcMainSignals.onIceConnectionStateChange.add(iceConnectionState => {
            if (iceConnectionState === 'failed') {
                signals.onError.emit({
                    error: 'Could not find ICE candidates',
                    errorCode: '#pex196',
                });
            }
        }),

        pcMainSignals.onError.add(error => {
            logger.error(error);
        }),

        pcMainSignals.onTransceiverChange.add(() => {
            cleanupStats();
            createStatsCollectors();
        }),

        pcMainSignals.onSecureCheckCode.add(callSignals.onSecureCheckCode.emit),

        mainStatsSignals.combinedRtcStatsSignal.add(
            ([
                audioIn,
                audioOut,
                videoIn,
                videoOut,
                presoVideoIn,
                presoVideoOut,
            ]) => {
                const stats = {
                    inbound: {
                        audio: audioIn,
                        video: videoIn,
                        preso: presoVideoIn,
                    },
                    outbound: {
                        audio: audioOut,
                        video: videoOut,
                        preso: presoVideoOut,
                    },
                };
                callSignals.onRtcStats.emit(stats);
            },
        ),

        mainStatsSignals.combinedCallQualityStatsSignal.add(
            ([audioIn, audioOut]) => {
                callSignals.onCallQualityStats.emit({
                    inbound: {audio: audioIn as AudioQualityStats}, // FIXME: stats can be either AudioQualityStats | VideoQualityStats
                    outbound: {audio: audioOut as AudioQualityStats},
                });
            },
        ),

        mainStatsSignals.combinedCallQualitySignal.add(
            callSignals.onCallQuality.emit,
        ),

        mainStatsSignals.videoIn.onRtcStats.add(stats => {
            if (stats) {
                const update = stats.packetsTransmitted;
                if (update < videoPacketsReceived) {
                    // likely a stale update from just recovered connection
                    return;
                }
                if (
                    videoPacketsReceived !== 0 &&
                    videoPacketsReceived === update
                ) {
                    logger.info(
                        'Triggering ICE restart due to packet stagnation',
                    );
                    const resumeStatsUpdates = [
                        statsCollectors.inbound.video?.resetStats(),
                        statsCollectors.outbound.video?.resetStats(),
                        statsCollectors.inbound.audio?.resetStats(),
                        statsCollectors.outbound.audio?.resetStats(),
                    ];

                    addOnceApiCallback('newCandidate', () => {
                        /**
                         * Stats could start flowing before new packets.
                         * Increment the received packets in order to avoid false negatives
                         */
                        videoPacketsReceived++;
                        resumeStatsUpdates.forEach(resume => resume?.());
                        callSignals.onReconnected.emit();
                    });
                    callSignals.onReconnecting.emit();
                    restartIce(peer);
                }
                videoPacketsReceived = update;
            }
        }),
    ];

    const cleanup = () => {
        detachApiSignals = detachApiSignals.flatMap(detach => {
            detach();
            return [];
        });
        detachPCSignals = detachPCSignals.flatMap(detach => {
            detach();
            return [];
        });
        cleanupStats();
        apiOneTimeCallbacks = {};
    };

    const close = () => {
        called = false;
        if (peer.connectionState === 'closed') {
            return;
        }
        peer.close();
    };

    // Start negotiation after successfully subscribing to all signals
    pcMainSignals.onOfferRequired.emit();

    return {
        get peer() {
            return peer;
        },
        get dataChannel() {
            const [config] = peer.getDataChannelConfigs();
            return config?.dataChannel;
        },
        cleanup,
        close,
    };
};
