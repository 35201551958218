import {useState, useLayoutEffect, useEffect, useCallback} from 'react';

import type {PreviewStreamController} from '@pexip/media';

import {logger} from '../logger';

const usePreviewMedia = (controller: PreviewStreamController) => {
    const [previewMedia, setPreviewMedia] = useState(controller.media);

    useLayoutEffect(
        () =>
            controller.onMediaChanged(media => {
                setPreviewMedia(media);
            }),
        [controller],
    );

    return previewMedia;
};

const useUpdatingPreview = (controller: PreviewStreamController) => {
    const [updatingPreview, setUpdatingPreview] = useState(false);

    useEffect(
        () =>
            controller.onUpdatingPreview(updating => {
                setUpdatingPreview(updating);
            }),
        [controller],
    );

    return updatingPreview;
};

export const usePreviewControllerHandler = ({
    applyChanges,
    close,
    controller,
    onHandleSave,
}: {
    applyChanges: Array<() => boolean>;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
    controller: PreviewStreamController;
    onHandleSave?: () => void;
}) => {
    const [isSaving, setSaving] = useState(false);

    const previewMedia = usePreviewMedia(controller);
    const updatingPreview = useUpdatingPreview(controller);

    const handleSave = useCallback(
        async (e: React.SyntheticEvent<HTMLElement>) => {
            logger.debug('Handle saving settings modal');
            if (onHandleSave) {
                onHandleSave();
            }
            setSaving(true);
            const force = applyChanges.reduce((needed, applyChange) => {
                const mediaUpdateNeeded = applyChange();
                if (!needed && mediaUpdateNeeded) {
                    needed = mediaUpdateNeeded;
                }
                return needed;
            }, false);

            await controller?.applyChanges(force);
            setSaving(false);

            close(e);
        },
        [applyChanges, close, controller, onHandleSave],
    );

    return {
        handleSave,
        isSaving,
        previewMedia,
        updatingPreview,
    };
};

export type PreviewControllerHandler = ReturnType<
    typeof usePreviewControllerHandler
>;
